import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col" 
import axios from "axios"; 
export default class Contact extends React.Component {
  constructor(props){
    super(props);
    this.state = {name: "", phone: "", email: "",service:"", message: "",success:""};
  }
 
  handleFields = e => this.setState({ [e.target.name]: e.target.value });
  handleForm = e => { 
    axios.post(
        "https://formcarry.com/s/rXL0U2wVJH", 
        this.state, 
        {headers: {"Accept": "application/json"}}
      )
      .then(function (response) {
        
        // access response.data in order to check formcarry response
        if(response.data.success) {

          e.success="Thank you for contacting us"
        } else {
          // handle error
          e.success="Form isnt submitted check your network"
        }
 
      })
      .catch(function (error) {
        e.success="Error when submitting the form."
      });
    this.render();
    e.preventDefault();
  };
  render() {
  return (
    <Layout>
      <SEO title="Contact" />
      
        <Row style={{
          position: "relative",
          paddingRight: "10%",
          paddingLeft: "10%",
          paddingTop: "50px",
          paddingBottom: "50px",
          marginBottom: "10px"
        }}>
          <Col xs={8} md={6} >
            <iframe title="map "
              frameBorder="0"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.1284057778885!2d38.761168414733966!3d8.960313993568521!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b84761963a8a9%3A0x7423e220f797f65c!2sTrans%20Ethiopia%20Tower!5e0!3m2!1sen!2set!4v1583053348360!5m2!1sen!2set"
              style={{
                border: "0",
                allowfullscreen: "",
                width: "100%",
                height: "80%",
                padding: "20px",
              }}
            ></iframe>
          </Col>
          <Col xs={8} md={6}
            style={{ 
              borderWidth: "1.5px",
              borderColor: "#4c7ba917",
              borderStyle: "groove",
              paddingTop: "0px" 
            }}
          >
            <div className="appointment-wrap  align-items-center">
              <form onSubmit={this.handleForm}
               
                className="appointment-form ftco-animate"
                name="EmailForm"
                method="POST"
                encType="multipart/form-data" 
              >
                <h5 className="pb-3 align-left mbr-fonts-style display-4" style={{margin:"auto"}}>
                  Contact Us
                </h5>
                <div className="">
                  <div className="form-group">
                    <input onChange={this.handleFields}
                      type="text"
                      className="form-control"
                      placeholder="Full Name"
                      name="name"
                    />
                  </div>
                  <div className="form-group">
                    <input onChange={this.handleFields}
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      name="email"
                    />
                  </div>
                  <div className="form-group">
                    <input onChange={this.handleFields}
                      type="text"
                      className="form-control"
                      placeholder="Phone"
                      name="phone"
                    />
                  </div>
                </div>
                <div className="">
                  <div className="form-group">
                    <div className="form-field">
                      <div className="select-wrap">
                        <div className="icon">
                          <span className="fa fa-chevron-down"></span>
                        </div>
                        <select name="service" id="service" className="form-control" >
                          <option    disabled>Select the Service you want</option>
                          <option  value="cargo handling">Cargo Handling</option>
                          <option value="custom clearing">Custom Clearing</option>
                          <option value="port handling">Port Handling</option>
                          <option value="transportation">Transportation</option>
                          <option value="other service">Other Services</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="form-group">
                    <textarea
                      name="message" 
                      cols="30"
                      rows="4"
                      className="form-control"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <input
                      style={{
                        backgroundColor: "#ffa931",
                        textAlign: "center",
                      }}
                      type="submit"
                      value="Send"
                      className="btn btn-primary  form-control"
                    />
                  </div>
                  <div className="form-group"> <p value={this.state.success}>{this.state.success}</p></div>
                </div>
              </form>
            </div>
          </Col>
        </Row> 
    </Layout>
  )
                    
 }
}